import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'selector',
    loadChildren: () => import('./selector/selector.module').then( m => m.SelectorPageModule)
  },
  {
    path: 'user-create',
    loadChildren: () => import('./user-create/user-create.module').then( m => m.UserCreatePageModule)
  },
  {
    path: 'user-detail',
    loadChildren: () => import('./user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },
  {
    path: 'news-create',
    loadChildren: () => import('./news-create/news-create.module').then( m => m.NewsCreatePageModule)
  },
  {
    path: 'workshop-create',
    loadChildren: () => import('./workshop-create/workshop-create.module').then( m => m.WorkshopCreatePageModule)
  },
  {
    path: 'workshop-edit',
    loadChildren: () => import('./workshop-edit/workshop-edit.module').then( m => m.WorkshopEditPageModule)
  },
  {
    path: 'news-edit',
    loadChildren: () => import('./news-edit/news-edit.module').then( m => m.NewsEditPageModule)
  },
  {
    path: 'part-edit',
    loadChildren: () => import('./part-edit/part-edit.module').then( m => m.PartEditPageModule)
  },
  {
    path: 'pending-create',
    loadChildren: () => import('./pending-create/pending-create.module').then( m => m.PendingCreatePageModule)
  },
  {
    path: 'channel-create',
    loadChildren: () => import('./channel-create/channel-create.module').then( m => m.ChannelCreatePageModule)
  },
  {
    path: 'channel-edit',
    loadChildren: () => import('./channel-edit/channel-edit.module').then( m => m.ChannelEditPageModule)
  },
  {
    path: 'support-detail',
    loadChildren: () => import('./support-detail/support-detail.module').then( m => m.SupportDetailPageModule)
  },
  {
    path: 'admin-create',
    loadChildren: () => import('./admin-create/admin-create.module').then( m => m.AdminCreatePageModule)
  },
  {
    path: 'admin-edit',
    loadChildren: () => import('./admin-edit/admin-edit.module').then( m => m.AdminEditPageModule)
  },
  {
    path: 'newsmer-create',
    loadChildren: () => import('./newsmer-create/newsmer-create.module').then( m => m.NewsmerCreatePageModule)
  },
  {
    path: 'newsmer-edit',
    loadChildren: () => import('./newsmer-edit/newsmer-edit.module').then( m => m.NewsmerEditPageModule)
  },
  {
    path: 'carcate-create',
    loadChildren: () => import('./carcate-create/carcate-create.module').then( m => m.CarcateCreatePageModule)
  },
  {
    path: 'carcate-edit',
    loadChildren: () => import('./carcate-edit/carcate-edit.module').then( m => m.CarcateEditPageModule)
  },
  {
    path: 'part-create',
    loadChildren: () => import('./part-create/part-create.module').then( m => m.PartCreatePageModule)
  },
  {
    path: 'selector2',
    loadChildren: () => import('./selector2/selector2.module').then( m => m.Selector2PageModule)
  },
  {
    path: 'selector3',
    loadChildren: () => import('./selector3/selector3.module').then( m => m.Selector3PageModule)
  },
  {
    path: 'voucher-create',
    loadChildren: () => import('./voucher-create/voucher-create.module').then( m => m.VoucherCreatePageModule)
  },
  {
    path: 'voucher-edit',
    loadChildren: () => import('./voucher-edit/voucher-edit.module').then( m => m.VoucherEditPageModule)
  },
  {
    path: 'voucher-dis',
    loadChildren: () => import('./voucher-dis/voucher-dis.module').then( m => m.VoucherDisPageModule)
  },
  {
    path: 'selector-many',
    loadChildren: () => import('./selector-many/selector-many.module').then( m => m.SelectorManyPageModule)
  },
  {
    path: 'playground',
    loadChildren: () => import('./playground/playground.module').then( m => m.PlaygroundPageModule)
  },
  {
    path: 'part-user-create',
    loadChildren: () => import('./part-user-create/part-user-create.module').then( m => m.PartUserCreatePageModule)
  },
  {
    path: 'part-user-edit',
    loadChildren: () => import('./part-user-edit/part-user-edit.module').then( m => m.PartUserEditPageModule)
  },
  {
    path: 'voucher-upload',
    loadChildren: () => import('./voucher-upload/voucher-upload.module').then( m => m.VoucherUploadPageModule)
  },
  {
    path: 'bulk-create',
    loadChildren: () => import('./bulk-create/bulk-create.module').then( m => m.BulkCreatePageModule)
  },
  {
    path: 'bulk-edit',
    loadChildren: () => import('./bulk-edit/bulk-edit.module').then( m => m.BulkEditPageModule)
  },
  {
    path: 'bulk-payment-details',
    loadChildren: () => import('./bulk-payment-details/bulk-payment-details.module').then( m => m.BulkPaymentDetailsPageModule)
  },
  {
    path: 'loan-pending-details',
    loadChildren: () => import('./loan-pending-details/loan-pending-details.module').then( m => m.LoanPendingDetailsPageModule)
  },
  {
    path: 'services-create',
    loadChildren: () => import('./services-create/services-create.module').then( m => m.ServicesCreatePageModule)
  },
  {
    path: 'services-edit',
    loadChildren: () => import('./services-edit/services-edit.module').then( m => m.ServicesEditPageModule)
  },
  {
    path: 'faq-create',
    loadChildren: () => import('./faq-create/faq-create.module').then( m => m.FaqCreatePageModule)
  },
  {
    path: 'faq-edit',
    loadChildren: () => import('./faq-edit/faq-edit.module').then( m => m.FaqEditPageModule)
  },  {
    path: 'selector-many2',
    loadChildren: () => import('./selector-many2/selector-many2.module').then( m => m.SelectorMany2PageModule)
  },
  {
    path: 'invoice-view',
    loadChildren: () => import('./invoice-view/invoice-view.module').then( m => m.InvoiceViewPageModule)
  },
  {
    path: 'user-upload',
    loadChildren: () => import('./user-upload/user-upload.module').then( m => m.UserUploadPageModule)
  },
  {
    path: 'users-selection',
    loadChildren: () => import('./users-selection/users-selection.module').then( m => m.UsersSelectionPageModule)
  },
  {
    path: 'hotline-create',
    loadChildren: () => import('./hotline-create/hotline-create.module').then( m => m.HotlineCreatePageModule)
  },
  {
    path: 'hotline-edit',
    loadChildren: () => import('./hotline-edit/hotline-edit.module').then( m => m.HotlineEditPageModule)
  },
  {
    path: 'mall-product-create',
    loadChildren: () => import('./mall-product-create/mall-product-create.module').then( m => m.MallProductCreatePageModule)
  },
  {
    path: 'mall-product-edit',
    loadChildren: () => import('./mall-product-edit/mall-product-edit.module').then( m => m.MallProductEditPageModule)
  },
  {
    path: 'mall-order-details',
    loadChildren: () => import('./mall-order-details/mall-order-details.module').then( m => m.MallOrderDetailsPageModule)
  },
  {
    path: 'mall-voucher-create',
    loadChildren: () => import('./mall-voucher-create/mall-voucher-create.module').then( m => m.MallVoucherCreatePageModule)
  },
  {
    path: 'mall-voucher-edit',
    loadChildren: () => import('./mall-voucher-edit/mall-voucher-edit.module').then( m => m.MallVoucherEditPageModule)
  },
  {
    path: 'mall-voucher-dis',
    loadChildren: () => import('./mall-voucher-dis/mall-voucher-dis.module').then( m => m.MallVoucherDisPageModule)
  },
  {
    path: 'mall-add-payment',
    loadChildren: () => import('./mall-add-payment/mall-add-payment.module').then( m => m.MallAddPaymentPageModule)
  },
  {
    path: 'mall-merchant-news-create',
    loadChildren: () => import('./mall-merchant-news-create/mall-merchant-news-create.module').then( m => m.MallMerchantNewsCreatePageModule)
  },
  {
    path: 'mall-merchant-news-edit',
    loadChildren: () => import('./mall-merchant-news-edit/mall-merchant-news-edit.module').then( m => m.MallMerchantNewsEditPageModule)
  },
  {
    path: 'mall-category-create',
    loadChildren: () => import('./mall-category-create/mall-category-create.module').then( m => m.MallCategoryCreatePageModule)
  },
  {
    path: 'mall-category-edit',
    loadChildren: () => import('./mall-category-edit/mall-category-edit.module').then( m => m.MallCategoryEditPageModule)
  },
  {
    path: 'selectmallvoucher',
    loadChildren: () => import('./selectmallvoucher/selectmallvoucher.module').then( m => m.SelectmallvoucherPageModule)
  },
  {
    path: 'mallvoucherdetail',
    loadChildren: () => import('./mallvoucherdetail/mallvoucherdetail.module').then( m => m.MallvoucherdetailPageModule)
  },
  {
    path: 'user-mall-product-create',
    loadChildren: () => import('./user-mall-product-create/user-mall-product-create.module').then( m => m.UserMallProductCreatePageModule)
  },
  {
    path: 'user-mall-product-edit',
    loadChildren: () => import('./user-mall-product-edit/user-mall-product-edit.module').then( m => m.UserMallProductEditPageModule)
  },
  {
    path: 'user-news-create',
    loadChildren: () => import('./user-news-create/user-news-create.module').then( m => m.UserNewsCreatePageModule)
  },
  {
    path: 'user-news-edit',
    loadChildren: () => import('./user-news-edit/user-news-edit.module').then( m => m.UserNewsEditPageModule)
  },
  {
    path: 'user-category-edit',
    loadChildren: () => import('./user-category-edit/user-category-edit.module').then( m => m.UserCategoryEditPageModule)
  },
  {
    path: 'user-category-create',
    loadChildren: () => import('./user-category-create/user-category-create.module').then( m => m.UserCategoryCreatePageModule)
  },
  {
    path: 'selectproducts',
    loadChildren: () => import('./selectproducts/selectproducts.module').then( m => m.SelectproductsPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
